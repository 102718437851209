import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-special-character-keyboard',
  templateUrl: './special-character-keyboard.component.html',
  styleUrls: ['./special-character-keyboard.component.css']
})
export class SpecialCharacterKeyboardComponent implements OnInit {
  
  @Output() characterSelect = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
  }

  onSpecialCharacterEnter(character: string) {
    this.characterSelect.emit(character);
  }
}
